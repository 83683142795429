<template>
  <v-layout wrap class="justify-center">
    <v-flex xs12 sm8 md4>
      <v-form v-model="valid" ref="reset-password" lazy-validation @submit.prevent="validate">
        <v-card class="elevation-4 mt-5 mx-2">
          <v-toolbar dark color="yellow darken-2" flat>
            <v-toolbar-title>{{ $t('Reset Password') }}</v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <template v-if="!result">
              <v-text-field v-model="email" :label="$t('Your e-mail')" ref="email" prepend-icon="mdi-email" class="mx-3 mt-4"
                            :rules="rules.email" required :loading="loading"
              />
            </template>
            <template v-else>
              <div> {{ $t(result.message) }}</div>
            </template>
          </v-card-text>

          <v-card-actions class="mobile-block">
            <v-spacer></v-spacer>
            <template v-if="!result">
              <router-link to="/login" class="mb-4 mt-1">{{ $t('Back to login') }}</router-link>
              <v-btn color="primary" class="mb-4 mt-1 mx-4 white--text" :loading="loading" :disabled="loading || !valid || !email"
                     depressed @click.stop="validate"
              >{{ $t('Send request') }}</v-btn>
            </template>
            <template v-else>
              <v-btn color="primary" class="mb-4 mt-1 mx-4 white--text" depressed @click.stop="$router.push('/login')"
              >{{ $t('Back to login') }}</v-btn>
              <v-btn color="primary" class="mb-4 mt-1 mx-4 white--text" depressed @click.stop="result = null"
              >{{ $t('Try Again') }}</v-btn>
            </template>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-flex>
  </v-layout>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'ResetPassword',
  data () {
    return {
      valid: false,
      email: '',
      rules: {
        required: value => !!value || this.$t('Required'),
        min: v => (v && v.length >= 8) || this.$t('Min 8 characters'),
        email: [
          v => !!v || this.$t('Required'),
          v => /.+@.+\..+/.test(v) || this.$t('E-mail must be valid')
        ]
      },
      showPassword: false,
      loading: false,
      result: null
    }
  },
  methods: {
    ...mapActions({
      requestReset: 'user/requestPasswordReset'
    }),
    validate () {
      if (!this.valid || !this.email) {
        this.$refs['reset-password'].validate();
        return
      }
      this.loading = true
      this.requestReset(this.email)
        .then(result => {
          this.result = result
        })
        .finally(() => {
          this.loading = false
        })
    }
  }
}
</script>
<style lang="scss" scoped>
 .mobile-block {
   @media (max-width: 400px) {
     display: block;
     text-align: center;
   }
 }
</style>

